<template>
  <div id="Footer">
    <div class="container-fluid fluid-90">
      <div class="row">
        <div class="col-lg-6">
          <div id="Certificates" class="justify-content-center">
            <img src="../assets/BACP-Logo.png" alt="BACP Registered Member 388006">
            <img src="../assets/Practitioner-Member-logo.jpg" alt="Practitioner Member of The Black, African and Asian Therapy Network">
          </div>
          <div id="Social-Links" class="justify-content-center">
            <a href="https://www.facebook.com/SupernovaPsychotherapy" target="_blank"><i class="bi-facebook"></i></a>
            <a href="https://www.instagram.com/supernovapsychotherapy" target="_blank"><i class="bi-instagram"></i></a>
          </div>
        </div>
        <div id="Contact-Links" class="col-lg-6">
          <a href="tel:+44-1243-941203">
            <i class="bi-telephone pr-2"></i>01243 941203
          </a>
          <a href="tel:+44-7882-574433">
            <i class="bi-phone pr-2"></i>07882 574433
          </a>
          <a href="mailto:help@supernovapsychotherapy.com">
            <i class="bi-envelope pr-2"></i>help@supernovapsychotherapy.com
          </a>
          <a href="./docs/PrivacyPolicy.pdf" target="_blank">Privacy Policy</a>
        </div>
      </div>
      <div class="row pt-5">
        <p>©Supernova Psychotherapy Services Ltd. 2021</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped lang="scss">
#Footer {
  background: $primary-colour;
  color: white;
  padding: 3rem 0 0.5rem;

  .logo {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
  }

  a {
    display: block;
    margin-bottom: 0.5rem;
    color: white;
  }

  #Certificates {
    img {
      display: block;
      width: auto;
      height: 75px;
      margin-bottom: 1rem;
      background: white;
      padding: 0.5rem;
      border-radius: 5px;
    }
  }

  #Social-Links {
    padding-top: 1rem;

    a {
      display: inline-block;
      background-color: white;
      border-radius: 100%;
      padding: 10px 15px;
      margin-right: 0.5rem;
      color: $primary-colour;
    }
  }
}
</style>