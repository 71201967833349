<template>
  <div class="bg-white">
    <div id="Header" class="container-fluid">
      <div class="row justify-content-center">
        <img class="w-100 p-3" src="../assets/logo.png"
             alt="Supernova Psychotherapy. Change at the Core, a universe of possibilities."/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
#Header img {
  max-width: 300px;
  padding: 1rem;
}
</style>