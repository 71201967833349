<template>
  <div class="container-fluid divider-wrapper">
    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  name: "SectionDivider"
}
</script>

<style scoped lang="scss">
.divider-wrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: black;
}
</style>