<template>
  <div id="About-Me-Page">
    <div id="About-Me" class="font-large">
      <div class="container-fluid fluid-90">
        <div class="row">
          <div class="col-lg-6 text-center">
            <h1 class="text-white">About Me</h1>
          </div>
          <div class="col-lg-6">
            <h1 class="text-white text-center">Shari A. Jessie</h1>
            <h2 class="text-white text-center">Director and Lead Psychotherapist</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 text-center">
            <img class="w-75" src="../assets/Shari-Jessie.jpg" alt="Shari Jessie">
          </div>
          <div class="col-lg-6">
            <div class="bg-semi-transparent p-2">
              <p>I have wanted to help people in some way for as long as I can remember. My own experiences, especially
                those of adversity, have shaped me as a therapist and as a person.</p>

              <p>I was born in New York and moved to the UK in my early twenties. My life has often been about
                navigating the intersections of my identity: class, race, gender, education level etc. I was raised by a
                single mother and I attended both government funded and private schools. I witnessed poverty and extreme
                privilege. All of this fed my curiousity about the world around me and the people that live in it.</p>

              <p>In university I studied Psychology, English, and Drama. I attended postgraduate programs during which I
                studied Psychology, Social Work, Adolescent Development, Drama Therapy, and Person-Centred Counselling.
                I spent 10 years working in secondary schools in London, providing support, psychotherapeutic intervention, advocacy, assessment, and
                monitoring to some of the most disenfranchised and underserved young people in the city.</p>

              <p>I have worked for the NHS and a low-cost counselling service in South London as a psychotherapist.</p>

              <p>I was fortunate enough to travel around the world, literally.</p>

              <p>The combination of these places and experiences has afforded me the challenge and privilege of meeting,
                learning from, and working with people and clients of all ages, backgrounds, religions, cultures and
                communities. <strong>I welcome and celebrate difference. I recognise the impact of oppression and
                marginalisation and take seriously my role in providing an environment where clients can feel safe to
                discuss any issue.</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-main">
      <div id="My-Specialties" class="container-fluid fluid-90 pt-5 pb-5">
        <div class="row">
          <div class="col-12">
            <h3 class="pb-3 text-center">I have worked with young people aged 11-25, adults aged 18-80, and
              families.</h3>
            <h4 class="pb-5"> Some of the Issues I’ve worked with:</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <StarItem>Unhappiness or Depression</StarItem>
            <StarItem>Anxiety</StarItem>
            <StarItem>Self-esteem</StarItem>
            <StarItem>Domestic abuse</StarItem>
            <StarItem>School refusal</StarItem>
            <StarItem>Self-harm</StarItem>
            <StarItem>Suicidal thoughts</StarItem>
            <StarItem>Culture and Identity issues</StarItem>
          </div>
          <div class="col-lg-6">
            <StarItem>Sexuality</StarItem>
            <StarItem>Youth offending</StarItem>
            <StarItem>Family conflict</StarItem>
            <StarItem>Workplace conflict/dissatisfaction</StarItem>
            <StarItem>Individual and Intergenerational Trauma</StarItem>
            <StarItem>Life transitions</StarItem>
            <StarItem>Relationship difficulties</StarItem>
            <StarItem>Fertility issues</StarItem>
          </div>
        </div>
      </div>
    </div>
    <div id="My-Approach-Heading">
      <div class="container-fluid fluid-90">
        <div class="row">
          <h1 class="w-100 text-center">My Approach</h1>
        </div>
      </div>
    </div>
    <div class="bg-main">
      <div class="container-fluid fluid-90 pt-5">
        <div class="row">
          <div class="col-12 pb-3">
            <p><strong>I am a qualified, BACP (British Association for Counselling and Psychotherapy) registered
              person-centred
              psychotherapist/counsellor. Being a registered member of the BACP means that I adhere to their guidelines,
              ensuring I work with you in a committed, confidential, and ethical way.</strong></p>

            <p>Person-centred psychotherapy and counselling is grounded in the idea that people are self-motivated to
              ‘live their best lives’ if the conditions around them are supportive. When relationships have empathic
              understanding, genuine and authentic communication, and acceptance of the other person, people feel more
              inclined to be honest to themselves about themselves and to seek new and more constructive ways of
              living.</p>

            <p>As a person-centred therapist, I provide a confidential, empathetic and non-judgmental, but also honest
              and
              real space for you to explore what’s going on for you. You take the lead in the work that we do together,
              and I will adapt my practice to meet your individual needs.</p>
          </div>
          <div class="col-lg-6">
            <div id="Highlighted-Quote" class="font-x-large text-white">
              <p>Carl Rogers, who developed the idea of person-centred therapy, once said:</p>
              <br/>
              <p>"We think we listen, but very rarely do we listen with real understanding, true empathy. Yet
                listening, of this very special kind, is one
                of the most potent forces for change that I know."</p>
            </div>
          </div>
          <div class="col-lg-6 font-x-large">
            <br class="d-none d-lg-block"/>
            <br/>
            <p class="pl-5 pr-5"><strong>That’s truly what I believe and hope for: that I can provide you with a space
              where you feel seen and
              heard. My hope is that our time together will facilitate the development of your self-awareness and sense
              of self so you can choose your way forward with greater self-insight.</strong></p>
          </div>
          <div class="col-12 pt-3">
            <p>Most importantly, I’m a person just like you trying navigate being unapologetically myself, and get through each day
              living life on my own terms. I get the process, how hard it can be, and I approach this work with
              sensitivity, humour and compassion. Being a part of your journey to feeling and living the way you want
              would be my absolute privilege. Let’s work together to figure out what’s getting in the way of that.</p>
          </div>
        </div>
      </div>
      <SectionDivider/>
      <div id="Offerings-Section" class="container-fluid fluid-90 pb-5">
        <div class="row pb-5">
          <div class="d-none d-lg-block col-lg-6">
            <img src="../assets/walking-therapy-2.jpg" alt="Walking Therapy in a Park"/>
          </div>
          <div class="col-12 col-lg-6">
            <h3 class="text-center pb-3">Walking Therapy</h3>
            <p>I offer confidential sessions that take place outside with me, the counsellor, and you, the client, walking
              side by side. The pace is always set by you and you are free to pause or sit if and when you want to.
              Walking side by side rather than sitting face to face enables some clients to feel more comfortable when
              sharing in the therapy process.</p>
            <p>Walking is a whole body experience and can lead to feeling more relaxed, less static or inhibited. Being
              outside often encourages freedom of expression and can create a powerful shared experience between
              counsellor and client that fosters engagement and enhances the supportive nature of the relationship.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="text-center pb-3">Sandtray Therapy</h3>
            <p>I infuse my practice with a variety of creative techniques, such as Sandtray therapy, that can lead to a
              deep exploration and awareness of your world. Sandtray can provide a way to communicate when verbalising
              becomes too difficult or when words alone are not enough.</p>
            <p>It is a form of expressive therapy that allows a person to create their own world inside the tray. These
              scenes act as a reflection of a person’s life. Sandtray therapy has been proven to help enable clients to
              speak more openly and confront their past traumas and ongoing problems. It  can provide a necessary safe space to explore what’s really going on. The creation of
              the tray and the understanding/interpretation of what is happening within it is completely led by you, the
              client.</p>
          </div>
          <div class="d-none d-lg-block col-lg-6">
            <img src="../assets/sandtray.jpg" alt="Sand-Tray Therapy"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarItem from "../components/ui/StarItem";
import SectionDivider from "../components/ui/SectionDivider";

export default {
  name: "AboutMePage",
  components: {SectionDivider, StarItem}
}
</script>

<style scoped lang="scss">
#About-Me {
  background-image: url("../assets/starry-bg.jpg");
  background-position: center;
  background-size: cover;
  padding: 5rem 0;
}

#My-Specialties {
  .star-item {
    margin-bottom: 0;
  }
}

#My-Approach-Heading {
  background-image: url("../assets/types-of-therapy.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 7.5rem 0;
  height: 100%;
  width: 100%;

  h1 {
    text-align: center;
  }
}

#Highlighted-Quote {
  background: radial-gradient(circle, rgba(73,97,196,1) 10%, rgba(27,20,100,1) 100%);
  padding: 3rem;

  h4 {
    color: white;
  }
}

#Offerings-Section {
  img {
    width: 100%;
  }
}

</style>