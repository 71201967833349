<template>
  <div id="We-Are-Born" class="row">
    <div class="col-lg-6 offset-lg-6">
      <div class="text-container">
        <p class="emphasis">We are born of those brilliant explosions</p>
        <p>As a person-centred therapist, I believe that we
          have within us the ability to impact our own universes with powerful self-determining change. That
          change begins when we can honestly acknowledge, experience, and accept who we are and what we’ve been
          through.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WeAreBorn"
}
</script>

<style scoped lang="scss">
#We-Are-Born {
  background: url("../../assets/noirlab2121a.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .text-container {
    background: $bg-semi-transparent;
    font-size: 28px;
    padding: 1.5rem 1.5rem;
    margin-right: -15px;

    .emphasis {
      font-size: 40px;
      text-transform: uppercase;
      padding-bottom: 5rem;
    }
  }

  @media (min-width: 992px) {
    .text-container {
      padding: 7.5rem 6.5rem;
    }
  }
}
</style>