<template>
  <div class="section-heading">
    <h1>{{ text }}</h1>
  </div>
</template>

<script>
export default {
  name: "SectionHeading",
  props: {
    text: String
  }
}
</script>

<style scoped lang="scss">
  .section-heading {
    h1 {
      padding: 1rem 0;
      font-family: $font-heading;
      text-align: center;
    }
  }
</style>