<template>
  <div id="About-Me-Page">
    <div class="bg-main">
      <div class="container">
        <SectionHeading text="Services and Fees"/>
        <SectionHeading text="How"/>
        <div class="row">
          <div class="col-lg-6">
            <p>I offer short term (usually 6-12 sessions) or open-ended therapy. On-going sessions will be periodically
              reviewed as agreed between you and I.</p>
            <p>Each session is 50 minutes long.</p>
            <p>I am flexible about the frequency of sessions (eg. weekly or bi-weekly)</p>
            <p>I offer a no cost, no commitment, 15-minute online or phone consultation for you to ask any questions and
              decide if I’m the right fit for you.</p>
            <p>So please, <a class="font-colour-blue" @click="$emit('goToPage', 'contact')">get in touch</a></p>
          </div>
          <div class="col-lg-6">
            <div id="Schedule-Table">
              <table class="m-auto">
                <tr>
                  <td><strong class="pr-5">Monday</strong></td>
                  <td>10:00am - 7:00pm</td>
                </tr>
                <tr>
                  <td><strong class="pr-5">Tuesday</strong></td>
                  <td>10:00am - 7:00pm</td>
                </tr>
                <tr>
                  <td><strong class="pr-5">Wednesday</strong></td>
                  <td>10:00am - 7:00pm</td>
                </tr>
                <tr>
                  <td><strong class="pr-5">Thursday</strong></td>
                  <td>10:00am - 7:00pm</td>
                </tr>
                <tr>
                  <td><strong class="pr-5">Friday</strong></td>
                  <td>10:00am - 4:00pm</td>
                </tr>
                <tr>
                  <td><strong class="pr-5">Saturday</strong></td>
                  <td>Closed</td>
                </tr>
                <tr>
                  <td><strong class="pr-5">Sunday</strong></td>
                  <td>Closed</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gradient">
      <div class="container-fluid fluid-90">
        <SectionHeading text="Where"/>
        <div class="row">
          <div class="col-12">
            <p>I work remotely (on-line via Zoom and telephone).</p>
            <p>I hold face-to-face sessions at The Pavilion located within Havenstoke Park in Chichester. The site is
              wheel-chair accessible, there is an on-site cafe and bathrooms are available.</p>
          </div>
        </div>
        <div class="row image-gallery py-5">
          <div class="col-lg-4">
            <img src="../assets/pavillion-room.jpg" alt="Pavillion Room" class="w-100 py-3 py-lg-0"/>
          </div>
          <div class="col-lg-4">
            <img src="../assets/pavillion-exterior.jpg" alt="Pavillion Exterior" class="w-100 py-3 py-lg-0"/>
          </div>
          <div class="col-lg-4">
            <img src="../assets/pavillion-cafe.jpg" alt="Pavillion Cafe" class="w-100 py-3 py-lg-0"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>For walking therapy, I am available to meet at any of the local parks in Chichester (inc. Bishop’s Palace
              Garden, Graylingwell Park, Priory Park, Jubilee Park, Oaklands Park), Chichester Canal, or West Dean Gardens (PO18 0RX).</p>
            <p>*Walking therapy requires an initial intake counselling session conducted in office or via online
              therapy.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-main">
      <div class="container-fluid fluid-90">
        <SectionHeading text="Fees"/>
        <div class="row">
          <div class="col-lg-4 text-center">
            <img src="../assets/virtual-counselling-image.jpg" alt="Virtual Counselling" class="w-100 pb-3"/>
            <h3>Online/Phone</h3>
            <p class="font-colour-grey">£65 per session</p>
          </div>
          <div class="col-lg-4 text-center">
            <img src="../assets/walking-therapy.jpg" alt="Walking Therapy" class="w-100 pb-3"/>
            <h3>Walking</h3>
            <p class="font-colour-grey">£75 per session</p>
          </div>
          <div class="col-lg-4 text-center">
            <img src="../assets/in-person-therapy.jpg" alt="In Person Therapy" class="w-100 pb-3"/>
            <h3>In Person</h3>
            <p class="font-colour-grey">£75 per session</p>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-12">
            <p>There is a sliding scale and concessions may be made for low-income (proof of benefits) or student
              clients (limited slots available). I'm happy to discuss your circumstances to see if I can accomodate
              you.</p>

            <p>Payments are to be made in advance of each session via bank transfer. Sessions are specifically reserved
              for you, and if payment is not received or arranged, the session will be cancelled.</p>

            <p>There is a 48 hour cancellation policy in place: any <strong>missed, rearranged</strong> or <strong>cancelled</strong>
              appointments without notice or with less than 24 hours notice will be <strong>payable in full.</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeading from "../components/ui/SectionHeading";

export default {
  name: "ServicesPage",
  components: {SectionHeading}
}
</script>

<style scoped lang="scss">
#Schedule-Table {
  table {
    background-color: white;

    td {
      padding: 0.25rem;
    }

  }
}

.bg-gradient {
  background-color: $primary-colour;
  background: radial-gradient(circle, rgba(205, 203, 231, 1) 0%, rgba(27, 20, 100, 1) 100%);
  color: white;
}

.image-gallery {
  img {
    height: 400px;
    object-fit: cover;
  }
}

</style>