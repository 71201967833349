<template>
  <div id="Contact-Page">
    <div class="bg-main py-5">
      <div class="container-fluid fluid-90">
        <div class="row">
          <div class="col-12">
            <h4>Please use the form below to send me a message. Alternatively you can text, Whatsapp, telephone or email
              directly, whatever method you prefer.</h4>
          </div>
        </div>
      </div>
    </div>
    <div id="Contact-Form-Wrapper">
      <div class="form-bg"></div>
      <div class="container-fluid fluid-90">
        <form id="Contact-Form" v-on:submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-6">
              <label for="First-Name-Input">First Name</label>
              <input id="First-Name-Input" v-model="firstName" type="text" placeholder="First Name"/>
            </div>
            <div class="col-lg-6">
              <label for="Last-Name-Input">Last Name</label>
              <input id="Last-Name-Input" v-model="lastName" type="text" placeholder="Last Name"/>
            </div>
            <div class="col-12 pt-5">
              <label for="Email-Input">Email</label>
              <input id="Email-Input" v-model="email" type="email" placeholder="Email address"/>
            </div>
            <div class="col-12 pt-5">
              <label for="Phone-Input">Phone</label>
              <input id="Phone-Input" v-model="phone" type="tel" placeholder="Telephone Number"/>
            </div>
            <div class="col-12 pt-5">
              <label for="Message-Input">Your Message</label>
              <textarea id="Message-Input" v-model="message" class="w-100" placeholder="Please enter your message here"/>
            </div>
            <div class="col-12 pt-5 text-center">
              <button v-show="!submitted && !submitting" type="submit" class="btn btn-primary">Submit</button>
              <p v-show="submitted" class="text-white">Thank you for getting in touch!</p>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="bg-main pt-5">
      <div class="container-fluid fluid-90">
        <div class="row">
          <div class="col-12">
            <p><strong>Inquiries will receive a response within 1 working day; please check your spam or junk folder if
              you do not receive a reply.</strong></p>
            <p>This is <strong>not an emergency service;</strong> if you need immediate support, some available
              resources are listed below:</p>
            <ul>
              <li><strong>Samaritans helpline:</strong> 116 123</li>
              <li><strong>SANEline:</strong> 0300 304 7000</li>
              <li><strong>National Suicide Prevention Helpline UK:</strong> 0800 689 5652</li>
              <li>Your local <strong>NHS</strong>&nbsp;
                <a href="https://www.nhs.uk/service-search/mental-health/find-an-urgent-mental-health-helpline">urgent
                  mental health helpline</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
  data() {
    return {
      firstName : '',
      lastName : '',
      email : '',
      phone : '',
      message : '',
      submitting: false,
      submitted: false
    }
  },
  methods: {
    async submitForm() {
      const self = this;
      this.submitting = true;
      this.$http.post(
          'https://docs.google.com/forms/u/1/d/e/1FAIpQLSf-c-93KH4cgBN2xzu3St61Jc-ZK8lfRLPD89LLAx9IXIzz9w/formResponse',
          this.getFormData()
      )
      .then(function () {
        // handle success
        self.submitted = true;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        self.submitted = true;
      })
      .then(function () {
        self.submitting = false;

        // Reset inputs
        self.firstName = '';
        self.lastName = '';
        self.email = '';
        self.phone = '';
        self.message = '';
      });
    },

    getFormData() {
      let bodyFormData = new FormData();

      bodyFormData.append('entry.2005620554', this.firstName);
      bodyFormData.append('entry.259415617', this.lastName);
      bodyFormData.append('entry.1045781291', this.email);
      bodyFormData.append('entry.1166974658', this.phone);
      bodyFormData.append('entry.839337160', this.message);

      return bodyFormData;
    }
  }
}
</script>

<style scoped lang="scss">
#Contact-Form-Wrapper {
  position: relative;
  overflow: hidden;
}

.form-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/chichester.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

#Contact-Form {
  padding: 3rem;

  label {
    color: white;
    display: block;
  }

  textarea {
    resize: none;
    min-height: 200px;
  }

  input, textarea {
    padding: 0.5rem;
  }

  button {
    background-color: $primary-colour;
    border-color: $primary-colour;
    font-size: $font-size-large;
  }
}

</style>