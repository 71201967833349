<template>
  <div id="App-Container">
    <NavBar :nav-items="pages" @go-to-page="navigateToPage($event)" />
    <Header />
    <SupernovaPage v-show="currentPageId === 'supernova'" @go-to-page="navigateToPage($event)" />
    <AboutMePage v-show="currentPageId === 'about'" />
    <ServicesPage v-show="currentPageId === 'services'" @go-to-page="navigateToPage($event)" />
    <ContactPage v-show="currentPageId === 'contact'" />
    <AppFooter />
  </div>
</template>

<script>
import Header from "./components/AppHeader";
import NavBar from "./components/NavBar";
import SupernovaPage from "./pages/SupernovaPage";
import AboutMePage from "./pages/AboutMePage";
import ServicesPage from "./pages/ServicesPage";
import AppFooter from "./components/AppFooter";
import ContactPage from "./pages/ContactPage";

export default {
  name: 'App',
  components: {
    ContactPage,
    AppFooter,
    ServicesPage,
    AboutMePage,
    SupernovaPage,
    NavBar,
    Header,
  },
  data() {
    return {
      currentPageId: 'supernova',
      pages: [
        {
          id: 'supernova',
          label: 'Why Supernova?',
        },
        {
          id: 'about',
          label: 'Me & My Approach',
        },
        {
          id: 'services',
          label: 'Services & Fees',
        },
        {
          id: 'contact',
          label: 'Contact Me',
        }
      ]
    }
  },
  methods: {
    navigateToPage(pageId) {
      this.currentPageId = pageId;
      window.scrollTo(0,0);
    }
  }
}
</script>

<style lang="scss">
#app {
  font-size: $font-size-medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Bitter', serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Manrope', sans-serif;
}

.bg-main {
  background: $bg-colour;
}

.bg-semi-transparent {
  background: $bg-semi-transparent;
}

.bg-accent {
  background: $primary-colour;
}

.fluid-90 {
  width: 90%;
}

.text-underline {
  text-decoration: underline;
}

.font-medium {
  font-size: $font-size-medium;
}

.font-large {
  font-size: $font-size-large;
}

.font-x-large {
  font-size: $font-size-x-large;
}

.font-xx-large {
  font-size: $font-size-xx-large;
}

.font-colour-light {
  color: $text-light;
}

.font-colour-grey {
  color: $text-grey;
}

.font-colour-blue {
  color: $primary-colour;
}

@media (max-width: 991.98px) {
  .fluid-90 {
    width: 100%;
  }
}
</style>
