<template>
  <div id="Nav-Bar" class="container-fluid sticky-top">
    <ul class="nav justify-content-center align-bottom">
      <li class="nav-item px-3" v-for="navItem in navItems" :key="navItem.id">
        <a class="nav-link" @click="$emit('goToPage', navItem.id)">{{ navItem.label }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavBar",

  props: {
    navItems: Array
  },

}
</script>

<style scoped lang="scss">
#Nav-Bar {
  z-index: 1000;
  background: $primary-colour;
}

.nav-item {
  cursor: pointer;

  a {
    font-family: $font-heading;
    color: $text-light;

    &:hover {
      text-decoration: underline;
    }

  }
}
</style>