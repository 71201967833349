<template>
  <div class="star-item">
    <i class="bi-stars"></i>
    <p class="font-large">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "StarItem"
}
</script>

<style scoped lang="scss">
.star-item {
  position: relative;
  padding-left: 20px;
  margin-bottom: 2rem;

  .bi-stars {
    position: absolute;
    left: 0;
  }
}
</style>