<template>
  <div id="Supernova-Page">
    <div id="Supernova-Heading">
      <div class="container-fluid fluid-90">
        <div class="row">
          <h1 class="font-xx-large">Why Supernova?</h1>
        </div>
      </div>
    </div>
    <div class="bg-main pt-5">
      <div class="container-fluid fluid-90">
        <div class="row pt-5">
          <div class="col-lg-5 offset-lg-1">
            <div id="Supernova-Intro">
              <h2>A Supernova is the blindingly bright and incredibly powerful explosion of a star. That’s
                pretty strange imagery to invoke when thinking about therapy, right?</h2>
              <br/>
              <h2>Here’s why I believe it’s not and why I’ve chosen it for my practice name.</h2>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 pt-sm-3 pt-lg-0">
            <StarItem>The process that leads to a Supernova is slow to build, but impossible to stop once it
              starts.
            </StarItem>
            <StarItem>The change that triggers a Supernova occurs at the center or core of the star.</StarItem>
            <StarItem>While a Supernova is an existing star which has reached the end of its life; when it
              explodes,
              all of its elements spread through space forming new stars, new planets, perhaps even new
              life.
            </StarItem>
            <StarItem>In fact, everything that makes up who we are comes from Supernovae: all the atoms in our
              bodies, the
              calcium in our bones and the iron in our blood.
            </StarItem>
          </div>
          <div class="col-1"></div>
        </div>
        <WeAreBorn/>
        <SectionDivider/>
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="text-center font-xx-large">
              <p>As Carl Rogers said:</p>
              <br/>
              <p>"The curious paradox is that when I accept myself as I am, then I can change."</p>
            </div>
          </div>
        </div>
        <SectionDivider/>
      </div>
    </div>
    <div id="Why-Therapy-Heading">
      <div class="container-fluid fluid-90">
        <div class="row">
          <h1 class="w-100 text-right">Why Therapy, Why Now?</h1>
        </div>
      </div>
    </div>
    <div class="bg-main pt-5">
      <div id="Why-Therapy-Points" class="container-fluid fluid-90">
        <div class="row">
          <div class="col-lg-6">
            <StarItem>You feel stuck in the past or in visions or longing for an unknown future, struggling to be
              present
              in the
              here and now.
            </StarItem>
          </div>
          <div class="col-lg-6">
            <StarItem>You feel a sense of disconnection from yourself, like there’s a person who you’re ‘supposed to be’
              or want to
              be and that’s different from who you experience yourself to be.
            </StarItem>
          </div>
          <div class="col-lg-6">
            <StarItem>You struggle to see yourself at all, to prioritise your needs or to make room for yourself.
            </StarItem>
          </div>
          <div class="col-lg-6">
            <StarItem>You find yourself struggling to understand and cope with yourself, others, and the world around
              you.
            </StarItem>
          </div>
          <div class="col-lg-6">
            <StarItem>You find you’re falling into negative or harmful patterns and want to break free from them.
            </StarItem>
          </div>
          <div class="col-lg-6">
            <StarItem>You’ve experienced or are experiencing oppression in any form and want a space to talk about how
              that makes you feel.
            </StarItem>
          </div>
          <div class="col-lg-6">
            <StarItem>The challenges of daily life, no matter what they are, feel like they are starting to overwhelm
              you. They feel like they’re taking up more and more space in your life.
            </StarItem>
          </div>
        </div>
        <div class="row pb-5">
          <div class="col-12">
            <h2 class="text-center">If any of this resonates with you, <a class="font-colour-blue" @click="$emit('goToPage', 'contact')">please get in touch</a>.</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WeAreBorn from "../components/sections/WeAreBorn";
import StarItem from "../components/ui/StarItem";
import SectionDivider from "../components/ui/SectionDivider";

export default {
  name: "SupernovaPage",
  components: {SectionDivider, WeAreBorn, StarItem}
}
</script>

<style scoped lang="scss">
#Supernova-Heading {
  background-image: url("../assets/supernova.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: 7.5rem 0;
  width: 100%;
  height: 100%;

  h1 {
    color: white;
  }
}

#Supernova-Intro {
  margin: 0 auto;

  h2 {
    color: $primary-colour;
  }
}

#Why-Therapy-Heading {
  background-image: url("../assets/recovery-support.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 7.5rem 0;
  height: 100%;
  width: 100%;

  h1 {
    color: white;
    text-align: right;
  }
}

</style>